import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../Tooltip';
import { getLongTermStockScreener, getLongTermStockPriceReturn } from '../../../services/data_api';
import './LongTermScreener.css';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// 首先定义 filterConfig
const filterConfig = {
  market_cap: {
    label: 'Market Cap',
    options: [
      { value: 'Large', label: 'Large' },
      { value: 'Medium', label: 'Medium' },
      { value: 'Small', label: 'Small' }
    ]
  },
  growth: {
    label: 'Growth',
    options: [
      { value: 'A', label: 'Very High' },
      { value: 'B', label: 'High' },
      { value: 'C', label: 'Medium' },
      { value: 'D', label: 'Low' },
      { value: 'E', label: 'Very Low' }
    ]
  },
  momentum: {
    label: 'Momentum',
    options: [
      { value: 'A', label: 'Very Strong' },
      { value: 'B', label: 'Strong' },
      { value: 'C', label: 'Neutral' },
      { value: 'D', label: 'Weak' },
      { value: 'E', label: 'Very Weak' }
    ]
  },
  profitability: {
    label: 'Profitability',
    options: [
      { value: 'A', label: 'Very Strong' },
      { value: 'B', label: 'Strong' },
      { value: 'C', label: 'Medium' },
      { value: 'D', label: 'Weak' },
      { value: 'E', label: 'Very Weak' }
    ]
  },
  premium: {
    label: 'Premium',
    options: [
      { value: 'A', label: 'Very Low' },
      { value: 'B', label: 'Low' },
      { value: 'C', label: 'Medium' },
      { value: 'D', label: 'High' },
      { value: 'E', label: 'Very High' }
    ]
  },
  risk: {
    label: 'Risk',
    options: [
      { value: 'A', label: 'Very Low' },
      { value: 'B', label: 'Low' },
      { value: 'C', label: 'Medium' },
      { value: 'D', label: 'High' },
      { value: 'E', label: 'Very High' }
    ]
  },
  sentiment: {
    label: 'Sentiment',
    options: [
      { value: 'A', label: 'Very Bullish' },
      { value: 'B', label: 'Bullish' },
      { value: 'C', label: 'Neutral' },
      { value: 'D', label: 'Bearish' },
      { value: 'E', label: 'Very Bearish' }
    ]
  },
  recommendation: {
    label: 'Recommendation',
    options: [
      { value: 'A', label: 'Strong Buy' },
      { value: 'B', label: 'Buy' },
      { value: 'C', label: 'Hold' },
      { value: 'D', label: 'Sell' },
      { value: 'E', label: 'Strong Sell' }
    ]
  }
};

const FilterTags = ({ filters, filterConfig, onRemove }) => {
  if (Object.values(filters).every(arr => arr.length === 0)) return null;

  return (
    <div className="longterm-screener-filter-tags">
      {Object.entries(filters).map(([type, values]) => 
        values.map(value => (
          <div key={`${type}-${value}`} className="filter-tag">
            <span className="filter-tag-type">{filterConfig[type].label}:</span>
            <span className="filter-tag-value">
              {filterConfig[type].options.find(opt => opt.value === value)?.label}
            </span>
            <button
              className="filter-tag-remove"
              onClick={() => onRemove(type, value)}
            >
              ×
            </button>
          </div>
        ))
      )}
    </div>
  );
};

const FilterDropdown = ({ type, value, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const updateDropdownPosition = () => {
    if (!isOpen || !dropdownRef.current || !buttonRef.current) return;
    
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // 计算下拉框的位置
    let top = buttonRect.bottom + window.scrollY;
    if (buttonRect.bottom + dropdownRect.height > viewportHeight) {
      // 如果下方空间不足，显示在按钮上方
      top = buttonRect.top + window.scrollY - dropdownRect.height;
    }

    dropdownRef.current.style.top = `${top}px`;
    dropdownRef.current.style.left = `${buttonRect.left}px`;
  };

  useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();
      window.addEventListener('scroll', updateDropdownPosition);
      window.addEventListener('resize', updateDropdownPosition);
    }
    return () => {
      window.removeEventListener('scroll', updateDropdownPosition);
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [isOpen]);

  return (
    <div className="filter-dropdown">
      <button 
        ref={buttonRef}
        className={`filter-dropdown-button ${value.length > 0 ? 'active' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{filterConfig[type].label}</span>
        <span className={`filter-dropdown-arrow ${isOpen ? 'active' : ''}`}>▼</span>
      </button>
      {isOpen && (
        <div ref={dropdownRef} className="filter-dropdown-content">
          {options.map(option => (
            <label key={option.value} className="filter-dropdown-item">
              <input
                type="checkbox"
                checked={value.includes(option.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange([...value, option.value]);
                  } else {
                    onChange(value.filter(v => v !== option.value));
                  }
                }}
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

const LongTermScreener = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [screenerData, setScreenerData] = useState([]);
  const [filters, setFilters] = useState({
    market_cap: [],
    growth: [],
    momentum: [],
    profitability: [],
    premium: [],
    risk: [],
    sentiment: [],
    recommendation: []
  });
  const [sortConfigs, setSortConfigs] = useState([
    { key: 'market_cap', direction: 'descending' }
  ]);
  const tableRef = useRef(null);
  const topScrollRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [priceData, setPriceData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getLongTermStockScreener();
        setScreenerData(response.data || []);
        setError(null);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching screener data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const promises = screenerData.map(stock => 
          getLongTermStockPriceReturn(stock.ticker)
            .then(data => ({ [stock.ticker]: data }))
            .catch(() => ({ [stock.ticker]: null }))
        );
        
        const responses = await Promise.all(promises);
        const priceMap = responses.reduce((acc, curr) => ({
          ...acc,
          ...curr
        }), {});
        
        setPriceData(priceMap);
      } catch (error) {
        console.error('Error fetching price data:', error);
      }
    };

    if (screenerData.length > 0) {
      fetchPriceData();
    }
  }, [screenerData]);

  const handleSort = (key) => {
    setSortConfigs(prevConfigs => {
      const existingIndex = prevConfigs.findIndex(config => config.key === key);
      const newConfigs = [...prevConfigs];

      if (existingIndex === -1) {
        // 如果是新的排序键，添加为降序
        return [...prevConfigs, { key, direction: 'descending' }];
      } else {
        // 如果已经存在，切换方向或移除
        const currentConfig = newConfigs[existingIndex];
        if (currentConfig.direction === 'descending') {
          currentConfig.direction = 'ascending';
          return newConfigs;
        } else {
          // 如果已经是升序，则移除这个排序条件
          newConfigs.splice(existingIndex, 1);
          // 如果移除后没有任何排序条件，恢复默认的市值降序排序
          if (newConfigs.length === 0) {
            return [{ key: 'market_cap', direction: 'descending' }];
          }
          return newConfigs;
        }
      }
    });
  };

  const getSortedData = () => {
    let data = [...screenerData];
    
    // 应用过滤器
    Object.entries(filters).forEach(([type, values]) => {
      if (values.length > 0) {
        data = data.filter(item => {
          if (type === 'market_cap') {
            // 使用 market_cap_rank 进行筛选，而不是 market_cap 值
            return values.includes(item.market_cap_rank);
          }
          return values.includes(item[type]);
        });
      }
    });

    // 应用多级排序
    if (sortConfigs.length > 0) {
      data.sort((a, b) => {
        // 遍历所有排序配置
        for (const { key, direction } of sortConfigs) {
          let valueA = a[key];
          let valueB = b[key];
          let comparison = 0;

          // 根据不同类型的数据进行比较
          switch (key) {
            case 'price':
              // 价格特殊处理
              valueA = priceData[a.ticker]?.current_price || 0;
              valueB = priceData[b.ticker]?.current_price || 0;
              comparison = valueA - valueB;
              break;

            case 'market_cap':
              // 市值直接比较数字
              comparison = valueA - valueB;
              break;

            case 'growth':
            case 'momentum':
            case 'profitability':
            case 'premium':
            case 'risk':
            case 'sentiment':
            case 'recommendation':
              // 等级评分比较 (A > B > C > D > E)
              const gradeOrder = { 'A': 5, 'B': 4, 'C': 3, 'D': 2, 'E': 1, null: 0 };
              comparison = (gradeOrder[valueA] || 0) - (gradeOrder[valueB] || 0);
              break;

            case 'ticker':
            default:
              // 字符串比较
              comparison = String(valueA || '').localeCompare(String(valueB || ''));
              break;
          }

          // 如果当前级别的比较结果不相等，立即返回结果
          if (comparison !== 0) {
            return direction === 'ascending' ? comparison : -comparison;
          }
          // 如果相等，继续下一个排序条件
        }
        return 0; // 所有排序条件都相等
      });
    }

    return data;
  };

  const handleTickerClick = (ticker) => {
    const stock = screenerData.find(s => s.ticker === ticker);
    if (stock) {
      const category = stock.market_cap_rank.toLowerCase() + '-cap';
      navigate(`/long-term/${category}/${ticker}`, { replace: true });
    }
  };

  const getGradeColor = (grade) => {
    const colors = {
      A: '#34a853', // Green
      B: '#93c47d', // Light Green
      C: '#fbbc04', // Yellow
      D: '#ea8235', // Orange
      E: '#ea4335'  // Red
    };
    return colors[grade] || '#666666';
  };

  const getGradeWidth = (description) => {
    const width = {
      'Very High': 100,
      'Very Low': 100,
      'Very Strong': 100,
      'Very Weak': 100,
      'Very Bullish': 100,
      'Very Bearish': 100,
      'Strong Buy': 100,
      'Strong Sell': 100,
      'High': 80,
      'Low': 80,
      'Strong': 80,
      'Weak': 80,
      'Bullish': 80,
      'Bearish': 80,
      'Buy': 80,
      'Sell': 80,
      'Average': 60,
      'Medium': 60,
      'Neutral': 60,
      'Hold': 60
    };
    return width[description] || 60;
  };

  const getGradeDescription = (grade, type) => {
    const descriptions = {
      growth: {
        'A': 'Very High',
        'B': 'High',
        'C': 'Medium',
        'D': 'Low',
        'E': 'Very Low'
      },
      momentum: {
        'A': 'Very Strong',
        'B': 'Strong',
        'C': 'Neutral',
        'D': 'Weak',
        'E': 'Very Weak'
      },
      profitability: {
        'A': 'Very Strong',
        'B': 'Strong',
        'C': 'Medium',
        'D': 'Weak',
        'E': 'Very Weak'
      },
      premium: {
        'A': 'Very Low',
        'B': 'Low',
        'C': 'Medium',
        'D': 'High',
        'E': 'Very High'
      },
      risk: {
        'A': 'Very Low',
        'B': 'Low',
        'C': 'Medium',
        'D': 'High',
        'E': 'Very High'
      },
      sentiment: {
        'A': 'Very Bullish',
        'B': 'Bullish',
        'C': 'Neutral',
        'D': 'Bearish',
        'E': 'Very Bearish'
      },
      recommendation: {
        'A': 'Strong Buy',
        'B': 'Buy',
        'C': 'Hold',
        'D': 'Sell',
        'E': 'Strong Sell'
      }
    };
    return descriptions[type]?.[grade] || grade;
  };

  const renderGrade = (grade, type) => {
    if (!grade) return '-';

    const description = getGradeDescription(grade, type);

    // 使用渐变条的指标
    if (['growth', 'momentum', 'profitability'].includes(type)) {
      const position = {
        'A': 90,
        'B': 70,
        'C': 50,
        'D': 30,
        'E': 10
      }[grade] || 50;

      return (
        <div className="longterm-screener-indicator">
          <div 
            className="longterm-screener-indicator-bar"
            style={{ 
              background: 'linear-gradient(to right, #ea4335, #fbbc04, #34a853)'
            }}
          >
            <div 
              className="longterm-screener-indicator-pointer"
              style={{ left: `${position}%` }}
            />
          </div>
          <div className={`longterm-screener-indicator-label grade-text-${description.toLowerCase().replace(' ', '-')}`}>
            {description}
          </div>
        </div>
      );
    }

    // 使用颜色块的指标
    if (['premium', 'risk', 'sentiment'].includes(type)) {
      const bars = ['A', 'B', 'C', 'D', 'E'];
      const gradeIndex = bars.indexOf(grade);

      return (
        <div className="longterm-screener-indicator">
          <div className="longterm-screener-bars-container">
            {bars.map((bar, index) => (
              <div 
                key={bar}
                className={`longterm-screener-bar-wrapper ${index <= gradeIndex ? 'active' : ''}`}
              >
                <div className={`longterm-screener-bar grade-color-${bar}`} />
              </div>
            ))}
          </div>
          <div className={`longterm-screener-indicator-label grade-text-${description.toLowerCase().replace(' ', '-')}`}>
            {description}
          </div>
        </div>
      );
    }

    // Recommendation 使用特殊样式
    if (type === 'recommendation') {
      return (
        <div className={`recommendation-grade recommendation-${description.replace(' ', '.')}`}>
          {description}
        </div>
      );
    }

    return description;
  };

  const renderMarketCap = (value, marketCapRank) => {
    const formatMarketCap = (value) => {
      return `$${value.toFixed(2)}B`;
    };

    const rankColors = {
      'Large': '#e3f2fd',
      'Medium': '#fff3e0',
      'Small': '#f3e5f5'
    };

    const rankTextColors = {
      'Large': '#1976d2',
      'Medium': '#f57c00',
      'Small': '#7b1fa2'
    };

    return (
      <div className="longterm-screener-metric">
        <span className="longterm-screener-metric-value">
          {formatMarketCap(value)}
        </span>
        <span 
          className="longterm-screener-metric-rank"
          style={{
            backgroundColor: rankColors[marketCapRank],
            color: rankTextColors[marketCapRank]
          }}
        >
          {marketCapRank}
        </span>
      </div>
    );
  };

  const renderPrice = (ticker) => {
    const data = priceData[ticker];
    if (!data) return '-';

    const price = data.current_price;
    const previousClose = data.previous_close;
    const changePercent = data.current_return;
    const change = price - previousClose;

    const isPositive = changePercent >= 0;
    const changeClass = isPositive ? 'positive-change' : 'negative-change';

    return (
      <div className="longterm-screener-price">
        <span className="price-value">${price.toFixed(2)}</span>
        <span className={`price-change ${changeClass}`}>
          {isPositive ? '+' : ''}{change.toFixed(2)} ({isPositive ? '+' : ''}{changePercent.toFixed(2)}%)
        </span>
      </div>
    );
  };

  const columns = [
    {
      key: 'ticker',
      label: 'Ticker',
      render: (value) => (
        <button className="ticker-link" onClick={() => handleTickerClick(value)}>
          {value}
        </button>
      )
    },
    {
      key: 'price',
      label: 'Price (Return)',
      render: (_, stock) => renderPrice(stock.ticker)
    },
    {
      key: 'market_cap',
      label: 'Market Cap',
      render: (value, stock) => renderMarketCap(value, stock.market_cap_rank)
    },
    {
      key: 'growth',
      label: 'Growth',
      tooltip: 'Evaluates company growth potential based on revenue trends, earnings growth, and market expansion.',
      render: (value) => renderGrade(value, 'growth')
    },
    {
      key: 'momentum',
      label: 'Momentum',
      tooltip: 'Measures price strength and trend based on technical indicators and relative performance.',
      render: (value) => renderGrade(value, 'momentum')
    },
    {
      key: 'profitability',
      label: 'Profitability',
      tooltip: 'Assesses company profitability through margins, ROE, and operational efficiency metrics.',
      render: (value) => renderGrade(value, 'profitability')
    },
    {
      key: 'premium',
      label: 'Premium',
      tooltip: 'Evaluates stock valuation relative to peers and historical levels using P/E, P/B, and other ratios.',
      render: (value) => renderGrade(value, 'premium')
    },
    {
      key: 'risk',
      label: 'Risk',
      tooltip: 'Measures financial health, business stability, and market risks through various indicators.',
      render: (value) => renderGrade(value, 'risk')
    },
    {
      key: 'sentiment',
      label: 'Sentiment',
      tooltip: 'Reflects market sentiment through analyst ratings, institutional holdings, and trading patterns.',
      render: (value) => renderGrade(value, 'sentiment')
    },
    {
      key: 'recommendation',
      label: 'Recommendation',
      tooltip: 'Overall investment recommendation based on comprehensive analysis of all factors.',
      render: (value) => renderGrade(value, 'recommendation')
    }
  ];

  // 处理滚动同步
  const handleScroll = (e, source) => {
    const scrollLeft = e.target.scrollLeft;
    if (source === 'top' && tableRef.current) {
      tableRef.current.scrollLeft = scrollLeft;
    } else if (source === 'bottom' && topScrollRef.current) {
      topScrollRef.current.scrollLeft = scrollLeft;
    }
  };

  // 监听表格宽度变化
  useEffect(() => {
    const updateTableWidth = () => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.scrollWidth);
      }
    };

    updateTableWidth();
    window.addEventListener('resize', updateTableWidth);
    return () => window.removeEventListener('resize', updateTableWidth);
  }, [screenerData]);

  // 渲染排序指示器
  const renderSortIndicators = () => {
    if (sortConfigs.length === 0) return null;

    return (
      <div className="longterm-screener-sort-indicators">
        <span className="sort-indicators-label">Sort by:</span>
        {sortConfigs.map((config, index) => (
          <div key={config.key} className="sort-indicator">
            <span className="sort-indicator-text">
              {columns.find(col => col.key === config.key)?.label}
              {index + 1 > 1 && <span className="sort-indicator-order">{index + 1}</span>}
            </span>
            <span className="sort-indicator-direction">
              {config.direction === 'ascending' ? '↑' : '↓'}
            </span>
            <button
              className="sort-indicator-remove"
              onClick={() => {
                setSortConfigs(prevConfigs => 
                  prevConfigs.filter(c => c.key !== config.key)
                );
              }}
            >
              ×
            </button>
          </div>
        ))}
      </div>
    );
  };

  if (loading) return <div className="longterm-screener-loading">Loading...</div>;
  if (error) return <div className="longterm-screener-error">Error: {error}</div>;

  return (
    <>
      <Helmet>
        <title>Long-term Stock Screener - FinStock Insights</title>
        <meta name="description" content="Screen and analyze long-term investment opportunities in Bitcoin-related stocks. Filter by market cap, growth, momentum, profitability, premium, risk, sentiment, and get investment recommendations." />
      </Helmet>
      <div className="longterm-screener-container">
        <h1 className="longterm-screener-title">Long-term Stock Screener</h1>
        
        <div className="longterm-screener-filters">
          {Object.entries(filterConfig).map(([type, config]) => (
            <FilterDropdown
              key={type}
              type={type}
              value={filters[type]}
              options={config.options}
              onChange={(newValue) => {
                setFilters(prev => ({
                  ...prev,
                  [type]: newValue
                }));
              }}
            />
          ))}
        </div>

        <FilterTags 
          filters={filters} 
          filterConfig={filterConfig} 
          onRemove={(type, value) => {
            setFilters(prev => ({
              ...prev,
              [type]: prev[type].filter(v => v !== value)
            }));
          }} 
        />

        {renderSortIndicators()}

        <div className="longterm-screener-scroll-container">
          {/* 顶部滚动条 */}
          <div 
            className="longterm-screener-top-scroll-wrapper"
            ref={topScrollRef}
            onScroll={(e) => handleScroll(e, 'top')}
          >
            <div 
              className="longterm-screener-scroll-content"
              style={{ width: `${tableWidth}px` }}
            />
          </div>

          {/* 表格容器 */}
          <div 
            className="longterm-screener-table-wrapper" 
            ref={tableRef}
            onScroll={(e) => handleScroll(e, 'bottom')}
          >
            <table className="longterm-screener-table">
              <thead>
                <tr>
                  {columns.map(column => (
                    <th 
                      key={column.key}
                      onClick={() => handleSort(column.key)}
                      className={`sortable-header ${
                        sortConfigs.find(config => config.key === column.key)
                          ? `sorted-${sortConfigs.find(config => config.key === column.key).direction}`
                          : ''
                      }`}
                      title="Click to sort"
                    >
                      <div className="longterm-screener-th-content">
                        {column.tooltip ? (
                          <Tooltip content={t(column.tooltip)} forceDirection="top">
                            <span>{column.label}</span>
                          </Tooltip>
                        ) : (
                          <span>{column.label}</span>
                        )}
                        <div className="sort-arrows">
                          <span className="sort-arrow up">▲</span>
                          <span className="sort-arrow down">▼</span>
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getSortedData().map(stock => (
                  <tr key={stock.ticker}>
                    {columns.map(column => (
                      <td key={column.key}>
                        {column.render ? 
                          column.render(stock[column.key], stock) : 
                          stock[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default LongTermScreener; 