import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  getStockScreenerData,
  getStockPriceReturn
} from '../../services/data_api';
import Tooltip from '../Tooltip';
import './StockScreener.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FilterTags = ({ filters, onRemove }) => {
  const columnLabels = {
    'market_cap': 'Market Cap',
    'bci': 'Correlation_BTC',
    'vp': 'Premium',
    'risk': 'Risk',
    'sentiment': 'Sentiment Rank',
    'recommendation': 'Recommendation'
  };

  return (
    <div className="screener-filter-tags">
      {Object.entries(filters).map(([column, values]) => {
        if (!values || values.length === 0) return null;
        return values.map(value => (
          <span key={`${column}-${value}`} className="screener-tag">
            <span className="screener-tag-content">
              <span className="screener-tag-category">{columnLabels[column]}:</span>
              <span className="screener-tag-value">{value}</span>
            </span>
            <button 
              className="screener-remove-tag" 
              onClick={() => onRemove(column, value)}
              aria-label="Remove filter"
            >
              ×
            </button>
          </span>
        ));
      })}
    </div>
  );
};

const FilterSection = ({ onFilter, filters }) => {
  const { t } = useTranslation();
  
  const filterTypes = [
    { 
      key: 'bci', 
      label: 'Correlation_BTC',
      tooltip: t('tooltips.bci'),
      options: [
        { value: 'Very Strong', label: 'Very Strong' },
        { value: 'Strong', label: 'Strong' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Weak', label: 'Weak' },
        { value: 'Very Weak', label: 'Very Weak' }
      ]
    },
    { 
      key: 'vp', 
      label: 'Premium',
      tooltip: t('tooltips.vp'),
      options: [
        { value: 'Very High', label: 'Very High' },
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
        { value: 'Very Low', label: 'Very Low' }
      ]
    },
    { 
      key: 'risk', 
      label: 'Risk',
      tooltip: t('tooltips.risk'),
      options: [
        { value: 'Very High', label: 'Very High' },
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
        { value: 'Very Low', label: 'Very Low' }
      ]
    },
    { 
      key: 'sentiment', 
      label: 'Sentiment',
      tooltip: t('tooltips.sentiment'),
      options: [
        { value: 'Very Bullish', label: 'Very Bullish' },
        { value: 'Bullish', label: 'Bullish' },
        { value: 'Neutral', label: 'Neutral' },
        { value: 'Bearish', label: 'Bearish' },
        { value: 'Very Bearish', label: 'Very Bearish' }
      ]
    },
    { 
      key: 'recommendation', 
      label: 'Recommendation',
      tooltip: t('tooltips.recommendation'),
      options: [
        { value: 'Strong Buy', label: 'Strong Buy' },
        { value: 'Buy', label: 'Buy' },
        { value: 'Hold', label: 'Hold' },
        { value: 'Sell', label: 'Sell' },
        { value: 'Strong Sell', label: 'Strong Sell' }
      ]
    },
    {
      key: 'market_cap',  // 添加新的筛选类型
      label: 'Market Cap',
      tooltip: 'Filter stocks by market capitalization',
      options: [
        { value: 'large-cap', label: 'Large Cap' },
        { value: 'medium-cap', label: 'Medium Cap' },
        { value: 'small-cap', label: 'Small Cap' }
      ]
    }
  ];

  const handleFilterChange = (key, value) => {
    const newFilters = { ...filters };
    if (!newFilters[key]) {
      newFilters[key] = [value];
    } else {
      const index = newFilters[key].indexOf(value);
      if (index === -1) {
        newFilters[key] = [...newFilters[key], value];
      } else {
        newFilters[key] = newFilters[key].filter(v => v !== value);
        if (newFilters[key].length === 0) {
          delete newFilters[key];
        }
      }
    }
    onFilter(newFilters);
  };

  return (
    <div className="stock-screener-filter-section">
      <div className="filter-section-header">
        <div className="filter-section-title">
          <svg className="filter-icon" viewBox="0 0 24 24" width="20" height="20">
            <path fill="currentColor" d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
          </svg>
          <span>Filters</span>
        </div>
      </div>
      <div className="stock-screener-filter-controls">
        {filterTypes.map(filter => (
          <div key={filter.key} className="stock-screener-filter-group">
            <div className="stock-screener-filter-header">
              <Tooltip content={filter.tooltip}>
                <span>{filter.label}</span>
              </Tooltip>
            </div>
            <div className="stock-screener-checkbox-group">
              {filter.options.map(option => (
                <label key={option.value} className="stock-screener-checkbox-label">
                  <input
                    type="checkbox"
                    checked={filters[filter.key]?.includes(option.value) || false}
                    onChange={() => handleFilterChange(filter.key, option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const StockScreener = () => {
  const { t } = useTranslation();
  const [stockData, setStockData] = useState(null);
  const [stockLoading, setStockLoading] = useState(true);
  const [stockError, setStockError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: 'market_cap_billion',
    direction: 'descending'
  });
  const [filters, setFilters] = useState({});
  const [activeCategory, setActiveCategory] = useState('');
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const topScrollRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [modalType, setModalType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  
  // 从 Redux 获取用户状态
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  // 检查 PRO 权限
  const checkAccess = (type) => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) return false;

      const subscription = JSON.parse(subscriptionData);
      if (!subscription || !subscription.data) return false;

      return subscription.data.subscription_status === 'active' && 
             new Date(subscription.data.current_period_end) > new Date();
    } catch (error) {
      console.error('Error checking access:', error);
      return false;
    }
  };

  // 检查订阅状态
  const isSubscriptionValid = () => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) return false;

      const subscription = JSON.parse(subscriptionData);
      if (!subscription || !subscription.data) return false;

      return subscription.data.subscription_status === 'active' && 
             new Date(subscription.data.current_period_end) > new Date();
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  };

  const columns = [
    { 
      key: 'ticker', 
      label: 'Ticker',
      render: (value) => (
        <button className="stock-link" onClick={() => handleTickerClick(value)}>
          {value}
        </button>
      )
    },
    { key: 'name', label: 'Name' },
    { 
      key: 'current_price_return',
      label: 'Current Price (Return)',
      render: (_, stock) => {
        if (!stock.current_price) return '-';
        return (
          <span>
            ${stock.current_price.toFixed(2)} (
            <span className={stock.current_return >= 0 ? 'stock-screener-positive' : 'stock-screener-negative'}>
              {stock.current_return.toFixed(2)}%
            </span>
            )
          </span>
        );
      }
    },
    { 
      key: 'recent_performance', 
      label: 'Recent Performance',
      tooltip: 'tooltips.recentPerformance',
      render: (value) => (
        <span className={value >= 0 ? 'stock-screener-positive' : 'stock-screener-negative'}>
          {(value * 100).toFixed(2)}%
        </span>
      )
    },
    { 
      key: 'market_cap_billion', 
      label: 'Market Cap (Billion)',
      render: (value) => `$${value.toFixed(2)}B`
    },
    { 
      key: 'btc_correlation_index', 
      label: 'Correlation_BTC',
      tooltip: 'tooltips.bci',
      render: (value) => renderGrade(value, 'bci')
    },
    { 
      key: 'valuation_premium', 
      label: 'Premium',
      tooltip: 'tooltips.vp',
      render: (value) => renderGrade(value, 'vp')
    },
    { 
      key: 'risk', 
      label: 'Risk',
      tooltip: 'tooltips.risk',
      render: (value) => renderGrade(value, 'risk')
    },
    { 
      key: 'sentiment_rank', 
      label: 'Sentiment Rank',
      tooltip: 'tooltips.sentiment',
      render: (value) => renderGrade(value, 'sentiment')
    },
    { 
      key: 'recommendation', 
      label: 'Recommendation',
      tooltip: 'tooltips.recommendation',
      render: (value) => renderGrade(value, 'recommendation')
    }
  ];

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        // 获取基础数据
        const data = await getStockScreenerData();
        
        // 并行获取所有股票的价格数据
        const pricePromises = data.data.map(stock => 
          getStockPriceReturn(stock.ticker)
            .then(priceData => ({
              ticker: stock.ticker,
              current_price: priceData.current_price,
              current_return: priceData.current_return
            }))
            .catch(() => ({
              ticker: stock.ticker,
              current_price: null,
              current_return: null
            }))
        );

        const priceResults = await Promise.all(pricePromises);
        
        // 将价格数据合并到原始数据中
        const enrichedData = {
          ...data,
          data: data.data.map(stock => {
            const priceData = priceResults.find(p => p.ticker === stock.ticker);
            return {
              ...stock,
              current_price: priceData?.current_price,
              current_return: priceData?.current_return
            };
          })
        };

        setStockData(enrichedData);
        setStockLoading(false);
      } catch (err) {
        setStockError(err.message);
        setStockLoading(false);
      }
    };

    fetchStockData();
  }, []);

  const sortData = (data, key) => {
    return [...data].sort((a, b) => {
      // 处理 Current Price 排序
      if (key === 'current_price_return') {
        return (a.current_price || 0) - (b.current_price || 0);
      }
      
      // 处理 BTC Correlation 排序
      if (key === 'btc_correlation_index') {
        const bciOrder = { 'Very Strong': 5, 'Strong': 4, 'Medium': 3, 'Weak': 2, 'Very Weak': 1 };
        const aValue = reverseGradeMap.bci[a[key]] || 0;
        const bValue = reverseGradeMap.bci[b[key]] || 0;
        return bciOrder[aValue] - bciOrder[bValue];
      }
      
      // 处理 Premium 排序
      if (key === 'valuation_premium') {
        const vpOrder = { 'Very High': 1, 'High': 2, 'Medium': 3, 'Low': 4, 'Very Low': 5 };
        const aValue = reverseGradeMap.vp[a[key]] || 0;
        const bValue = reverseGradeMap.vp[b[key]] || 0;
        return vpOrder[aValue] - vpOrder[bValue];
      }
      
      // 处理 Risk 排序
      if (key === 'risk') {
        const riskOrder = { 'Very High': 1, 'High': 2, 'Medium': 3, 'Low': 4, 'Very Low': 5 };
        const aValue = reverseGradeMap.risk[a[key]] || 0;
        const bValue = reverseGradeMap.risk[b[key]] || 0;
        return riskOrder[aValue] - riskOrder[bValue];
      }
      
      // 处理 Sentiment Rank 排序
      if (key === 'sentiment_rank') {
        const sentimentOrder = {
          'A': 5, // Very Bullish
          'B': 4, // Bullish
          'C': 3, // Neutral
          'D': 2, // Bearish
          'E': 1  // Very Bearish
        };
        // 直接使用字母等级进行排序，而不是转换后的描述文本
        return sentimentOrder[a[key]] - sentimentOrder[b[key]];
      }
      
      // 处理 Recommendation 排序
      if (key === 'recommendation') {
        const recommendationOrder = { 'Strong Buy': 5, 'Buy': 4, 'Hold': 3, 'Sell': 2, 'Strong Sell': 1 };
        const aValue = a[key] === 'A' ? 'Strong Buy' :
                      a[key] === 'B' ? 'Buy' :
                      a[key] === 'C' ? 'Hold' :
                      a[key] === 'D' ? 'Sell' :
                      a[key] === 'E' ? 'Strong Sell' : '';
        const bValue = b[key] === 'A' ? 'Strong Buy' :
                      b[key] === 'B' ? 'Buy' :
                      b[key] === 'C' ? 'Hold' :
                      b[key] === 'D' ? 'Sell' :
                      b[key] === 'E' ? 'Strong Sell' : '';
        return recommendationOrder[aValue] - recommendationOrder[bValue];
      }
      
      // 处理数值类型
      if (key === 'market_cap_billion' || key === 'recent_performance') {
        return parseFloat(a[key]) - parseFloat(b[key]);
      }
      
      // 处理字符串类型
      return a[key].toString().localeCompare(b[key].toString());
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    if (!sortConfig.key || !stockData?.data) return stockData?.data || [];
    const sortedData = sortData(stockData.data, sortConfig.key);
    return sortConfig.direction === 'descending' ? sortedData.reverse() : sortedData;
  };

  const gradeMap = {
    'Very Strong': 'A',
    'Strong': 'B',
    'Medium': 'C',
    'Weak': 'D',
    'Very Weak': 'E',
    'Very High': 'A',
    'High': 'B',
    'Low': 'D',
    'Very Low': 'E',
    'Very Bullish': 'A',
    'Bullish': 'B',
    'Neutral': 'C',
    'Bearish': 'D',
    'Very Bearish': 'E'
  };

  const reverseGradeMap = {
    'bci': {
      'A': 'Very Strong',
      'B': 'Strong',
      'C': 'Medium',
      'D': 'Weak',
      'E': 'Very Weak'
    },
    'vp': {
      'E': 'Very High',
      'D': 'High',
      'C': 'Medium',
      'B': 'Low',
      'A': 'Very Low'
    },
    'risk': {
      'E': 'Very High',
      'D': 'High',
      'C': 'Medium',
      'B': 'Low',
      'A': 'Very Low'
    },
    'sentiment': {
      'A': 'Very Bullish',
      'B': 'Bullish',
      'C': 'Neutral',
      'D': 'Bearish',
      'E': 'Very Bearish'
    }
  };

  const handleRemoveFilter = (column, value) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      if (newFilters[column]) {
        newFilters[column] = newFilters[column].filter(v => v !== value);
        if (newFilters[column].length === 0) {
          delete newFilters[column];
        }
      }
      return newFilters;
    });
  };

  const getFilteredData = () => {
    let data = getSortedData();
    
    Object.entries(filters).forEach(([column, values]) => {
      if (values && values.length > 0) {
        data = data.filter(item => {
          // 处理 Market Cap 筛选
          if (column === 'market_cap') {
            const marketCapCategory = getMarketCapCategory(item.ticker);
            return values.includes(marketCapCategory);
          }
          
          // 处理其他筛选条件
          const fieldName = {
            'sentiment': 'sentiment_rank',
            'recommendation': 'recommendation',
            'vp': 'valuation_premium',
            'bci': 'btc_correlation_index'
          }[column] || column;
          
          const itemValue = item[fieldName];
          
          return values.some(value => {
            // 将选项标签转换为对应的等级
            let grade;
            switch(column) {
              case 'recommendation':
                grade = {
                  'Strong Buy': 'A',
                  'Buy': 'B',
                  'Hold': 'C',
                  'Sell': 'D',
                  'Strong Sell': 'E'
                }[value];
                break;
              case 'bci':
                grade = {
                  'Very Strong': 'A',
                  'Strong': 'B',
                  'Medium': 'C',
                  'Weak': 'D',
                  'Very Weak': 'E'
                }[value];
                break;
              case 'vp':
                grade = {
                  'Very High': 'E',
                  'High': 'D',
                  'Medium': 'C',
                  'Low': 'B',
                  'Very Low': 'A'
                }[value];
                break;
              case 'risk':
                grade = {
                  'Very High': 'E',
                  'High': 'D',
                  'Medium': 'C',
                  'Low': 'B',
                  'Very Low': 'A'
                }[value];
                break;
              default:
                grade = gradeMap[value];
            }
            return itemValue === grade;
          });
        });
      }
    });
    
    return data;
  };

  const handleFilter = (newFilters) => {
    setFilters(newFilters);
  };

  const renderGrade = (grade, type) => {
    if (!grade) return '-';
    
    const getGradeColor = (grade, type) => {
      if (type === 'risk' || type === 'vp') {
        const colors = {
          'A': '#1B5E20',  // very low - 深绿色
          'B': '#388E3C',  // low - 绿色
          'C': '#FDD835',  // medium - 黄色
          'D': '#F57C00',  // high - 橙色
          'E': '#D32F2F'   // very high - 深红色
        };
        return colors[grade] || '#ddd';
      }
      
      const colors = {
        'A': '#388E3C',
        'B': '#388E3C',
        'C': '#FDD835', 
        'D': '#F57C00',
        'E': '#D32F2F'
      };
      return colors[grade] || '#ddd';
    };

    const getDescription = (type, grade) => {
      const descriptions = {
        bci: {
          'A': 'Very Strong',
          'B': 'Strong',
          'C': 'Medium',
          'D': 'Weak',
          'E': 'Very Weak'
        },
        vp: {
          'E': 'Very High',
          'D': 'High',
          'C': 'Medium',
          'B': 'Low',
          'A': 'Very Low'
        },
        risk: {
          'E': 'Very High',
          'D': 'High',
          'C': 'Medium',
          'B': 'Low',
          'A': 'Very Low'
        },
        sentiment: {
          'A': 'Very Bullish',
          'B': 'Bullish',
          'C': 'Neutral',
          'D': 'Bearish',
          'E': 'Very Bearish'
        },
        recommendation: {
          'A': 'Strong Buy',
          'B': 'Buy',
          'C': 'Hold',
          'D': 'Sell',
          'E': 'Strong Sell'
        }
      };
      return descriptions[type]?.[grade] || grade;
    };

    return (
      <div className="stock-screener-grade-container">
        {['bci', 'sentiment', 'recommendation'].includes(type) && (
          <>
            <div className="stock-screener-horizontal-indicator">
              <div className="stock-screener-marker" style={{
                left: grade === 'A' ? '100%' : 
                      grade === 'B' ? '75%' : 
                      grade === 'C' ? '50%' : 
                      grade === 'D' ? '25%' : '0%'
              }}></div>
            </div>
            <span className="stock-screener-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
        {['vp', 'risk'].includes(type) && (
          <>
            <div className="stock-screener-stacked-bars">
              {[...Array(5)].map((_, i) => (
                <div
                  key={i}
                  className="stock-screener-bar"
                  style={{
                    backgroundColor: i < (
                      grade === 'E' ? 5 :  // Changed from 'A'
                      grade === 'D' ? 4 :  // Changed from 'B'
                      grade === 'C' ? 3 :
                      grade === 'B' ? 2 :  // Changed from 'D'
                      grade === 'A' ? 1 : 0  // Changed from 'E'
                    ) ? getGradeColor(grade, type) : '#ddd'
                  }}
                />
              ))}
            </div>
            <span className="stock-screener-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
      </div>
    );
  };

  const getColumnAlignment = (key) => {
    if (['ticker', 'name'].includes(key)) {
      return 'stock-screener-align-left';
    }
    if (['close', 'recent_performance'].includes(key)) {
      return 'stock-screener-align-right';
    }
    return 'stock-screener-align-center';
  };

  // 添加一个辅助函数来判断股票的市值分类
  const getMarketCapCategory = (ticker) => {
    const smallCapStocks = ['BYON', 'SMLR', 'FRMO', 'CAN', 'HIVE', 'EXOD', 'BTBT', 'BITF', 'BTDR', 'HUT', 'IREN', 'CIFR', 'WULF', 'CLSK', 'CORZ', 'RIOT', 'MARA'];
    const mediumCapStocks = ['GLOB', 'SOFI', 'RDDT', 'HOOD', 'SQ', 'MSTR', 'COIN', 'CME', 'PYPL'];
    const largeCapStocks = ['SHOP', 'MELI', 'BLK', 'AMD', 'MA', 'V', 'TSLA', 'META', 'NVDA'];

    if (smallCapStocks.includes(ticker)) return 'small-cap';
    if (mediumCapStocks.includes(ticker)) return 'medium-cap';
    if (largeCapStocks.includes(ticker)) return 'large-cap';
    return 'small-cap'; // 默认分类
  };

  // 修改 handleTickerClick 函数
  const handleTickerClick = (ticker) => {
    // 检查是否已登录
    if (!isLoggedIn) {
      navigate('/login', { 
        state: { 
          from: location.pathname,
          redirectAfterLogin: true 
        } 
      });
      return;
    }

    // 检查是否有 PRO 权限，如果没有直接跳转到价格页面
    if (!checkAccess('pro')) {
      navigate('/pricing', { 
        state: { 
          from: location.pathname,
          redirectAfterPurchase: true 
        } 
      });
      return;
    }

    const marketCap = getMarketCapCategory(ticker);
    navigate(`/products/stocks/${marketCap}/${ticker}`);
  };

  // 添加一个 useEffect 来监听表格宽度
  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.scrollWidth);

      // 添加 ResizeObserver 以监听表格宽度变化
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setTableWidth(entry.target.scrollWidth);
        }
      });

      resizeObserver.observe(tableRef.current);

      // 清理函数
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [stockData]); // 当数据更新时重新计算

  // 同步滚动处理函数
  const handleScroll = (e, target) => {
    const scrollLeft = e.target.scrollLeft;
    if (target === 'top' && tableRef.current) {
      tableRef.current.scrollLeft = scrollLeft;
    } else if (target === 'bottom' && topScrollRef.current) {
      topScrollRef.current.scrollLeft = scrollLeft;
    }
  };

  if (stockLoading) return <div>Loading...</div>;
  if (stockError) return <div>Error: {stockError}</div>;
  if (!stockData?.data) return <div>No data available</div>;

  return (
    <div className="stock-screener-container">
      <h2 className="stock-screener-title">Stock Screener</h2>
      <div className="screener-filter-section">
        <FilterSection 
          onFilter={handleFilter} 
          filters={filters}
        />
        <FilterTags 
          filters={filters} 
          onRemove={handleRemoveFilter}
        />
      </div>
      <div className="stock-screener-scroll-container">
        {/* 顶部滚动条 */}
        <div 
          className="stock-screener-top-scroll-wrapper" 
          ref={topScrollRef}
          onScroll={(e) => handleScroll(e, 'top')}
        >
          <div 
            className="stock-screener-scroll-content" 
            style={{ width: `${tableWidth}px` }}  // 使用状态中的宽度
          />
        </div>

        {/* 表格区域 */}
        <div 
          className="stock-screener-table-wrapper" 
          ref={tableRef}
          onScroll={(e) => handleScroll(e, 'bottom')}
        >
          <table className="stock-screener-table">
            <thead>
              <tr>
                {columns.map(column => (
                  <th 
                    key={column.key} 
                    onClick={() => handleSort(column.key)}
                    className={`${getColumnAlignment(column.key)} sortable-header`}
                  >
                    <div className="stock-screener-th-content">
                      {column.tooltip ? (
                        <Tooltip content={t(column.tooltip)}>
                          <span>{column.label}</span>
                        </Tooltip>
                      ) : (
                        <span>{column.label}</span>
                      )}
                      {sortConfig.key === column.key && (
                        <span className="stock-screener-sort-indicator">
                          {sortConfig.direction === 'ascending' ? '↑' : '↓'}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getFilteredData().map(stock => (
                <tr key={stock.ticker}>
                  {columns.map(column => (
                    <td 
                      key={column.key}
                      className={getColumnAlignment(column.key)}
                    >
                      {column.render ? 
                        column.render(stock[column.key], stock) : 
                        stock[column.key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StockScreener; 