import React from 'react';
import './LongTermOverview.css';

const Overview = () => {
  return (
    <div className="longterm-overview">
      <div className="longterm-overview-header">
        <h1>Long-term Investment Overview</h1>
        <p className="longterm-overview-description">
          Coming Soon! We're working on bringing you comprehensive long-term investment insights.
        </p>
      </div>
      <div className="longterm-overview-content">
        <div className="longterm-overview-card">
          <div className="longterm-overview-icon">🚀</div>
          <h2>Stay Tuned!</h2>
          <p>We're building something amazing for long-term investors.</p>
        </div>
      </div>
    </div>
  );
};

export default Overview; 